import Image from 'next/image';

const LearnMore = () => {
    return (  
        <div className='relative h-fit md:-mt-8'>
            <span className="w-full flex justify-center mx-auto text-center relative md:top-0 -top-4 z-10">
                <div className='relative w-full h-[130px]'>
                    <Image loading="lazy"
                    src="https://cdn.fixat.mx/assets/home/img/conoce-mas-de-fixat.png"
                    alt="Conoce más del servicio fixat" 
                    title="Conoce más"
                    layout='fill'
                    objectFit='contain'
                    />
                </div>
            </span>
            <div className="w-full after:absolute after:w-full after:content-[''] md:after:h-[120px] after:h-[80px] after:bg-whatsapp-container md:after:top-11 after:top-14 after:left-0 md:after:rounded-t-[50%] after:rounded-none" id='!como-funciona'>
                <section id="section_001" className="w-full bg-whatsapp-container">
                    <div className="max-w-screen-0.5xl mx-auto xs:px-4 md:px-0">
                        <div className="flex justify-center w-full xs:-mb-12 md:mb-10 md:mt-8 px-4">
                            <h2 className="mb-4 font-fredoka text-4.5xl text-center">
                                Ahora es así de fácil pagar tus impuestos
                            </h2>
                        </div>
                        <div className="w-full flex flex-col">
                            <div className="w-full text-center md:text-left">
                                <div className="w-full flex flex-col  md:flex-row xs:text-center md:text-left">
                                    <div className="md:w-1/2 w-full xs:mb-7 md:mb-0 xs:pl-0 md:pl-4 flex xs:justify-center md:justify-end">
                                        <div>
                                            <div className="flex justify-start flex-row items-center xs:mt-20">
                                                <span className="pt-0.5 pr-1 text-5xl text-center h-16 w-16 border-big-number rounded-full font-fredoka border-solid border-6 mx-auto md:mx-0">
                                                    1
                                                </span>
                                            </div>
                                            <h3 className="text-3xl font-fredoka mt-4 float-left w-full">
                                                Ingresa tus datos <br></br>
                                                y regístrate en FIXAT®
                                            </h3>
                                            <span className="text-lg mt-4 float-left w-full">
                                                ¡Es muy fácil!
                                            </span>
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 w-full">
                                        <div className="xs:mt-4 md:-mt-6 xs:w-10/12 md:w-full mx-auto">
                                            <div className='relative xs:max-w-[calc(100%-4px)] xs:w-[300px] xs:max-h-[235px] xs:h-[350px] md:max-w-[427px] md:max-h-[359px] md:w-full md:h-[458px] mx-auto'>
                                                <Image loading="lazy"
                                                src="https://cdn.fixat.mx/assets/home/img/ingresa-tus-datos-inicio.png"
                                                title="Ingresa tus datos y registrate en Fixat" 
                                                alt="Ingresa tus datos"
                                                layout='fill'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full xs:text-center md:text-left">
                                <div className="w-full flex flex-col md:flex-row">
                                    <div className="md:w-1/2 w-full xs:hidden md:block">
                                        <div className="pl-20 mt-16 relative xs:max-w-[calc(100%-4px)] xs:w-[310px] xs:max-h-[230px] xs:h-[350px] md:max-w-[427px] md:max-h-[359px] md:w-full md:h-[458px] mx-auto">
                                            <Image loading="lazy"
                                            src="https://cdn.fixat.mx/assets/home/img/ingrese-sus-datos-fiscales.png" 
                                            title="Ingresa tus datos fiscales" 
                                            alt="Ingresa tus datos fiscales"
                                            layout='fill'
                                            />
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 w-full">
                                        <div className="xs:pr-0 md:pr-4">
                                            <div className="flex xs:justify-center md:justify-start flex-row items-center xs:mt-20">
                                                <span className="pt-0.5 text-5xl text-center h-16 w-16 border-big-number rounded-full font-fredoka border-solid border-6 mx-auto md:mx-0">
                                                    2
                                                </span>
                                            </div>
                                            <h3 className="text-3xl font-fredoka mt-4 float-left w-full mb-6">
                                            FIXAT® conecta tus<br></br>ingresos y facturas<br></br>¡En automático!
                                            </h3>
                                            <div className="w-full mx-auto">
                                                <span className="text-lg mt-8 text-center">
                                                Al ingresar tus datos fiscales, FIXAT® obtiene tus ingresos y gastos comprobados para realizar tu declaración vía electrónica.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 w-full xs:block md:hidden">
                                        <div className="mt-8 xs:w-10/12 md:w-full mx-auto">
                                            <div className='relative xs:max-w-[calc(100%-4px)] xs:w-[300px] xs:max-h-[235px] xs:h-[350px] md:max-w-[427px] md:max-h-[359px] md:w-full md:h-[458px] mx-auto'>
                                                <Image loading="lazy"
                                                src="https://cdn.fixat.mx/assets/home/img/ingrese-sus-datos-fiscales.png"
                                                title="Ingresa tus datos fiscales" 
                                                alt="Ingresa tus datos fiscales"
                                                layout='fill'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full text-center md:text-left">
                                <div className="w-full flex flex-col md:flex-row xs:text-center md:text-left">
                                    <div className="md:w-1/2 w-full xs:mb-7 md:mb-0 xs:px-1 md:pl-4 flex xs:justify-center md:justify-end">
                                        <div className='w-full max-w-[450px]'>
                                            <div className="flex justify-start flex-row items-center xs:mt-20">
                                                <span className="pt-0.5 pr-1 text-5xl text-center h-16 w-16 border-big-number rounded-full font-fredoka border-solid border-6 mx-auto md:mx-0">
                                                    3
                                                </span>
                                            </div>
                                            <h3 className="text-3xl font-fredoka mt-4 float-left">
                                                Recibes tu declaración y <span className='xs:inline-block md:block'>¡Pagas tus impuestos!</span>
                                            </h3>
                                            <span className="text-lg float-left mt-4">
                                            ¡Listo! Al finalizar tu declaración, FIXAT® te envía tu documentación para que pagues tus impuestos desde tu computadora o en cualquier establecimiento autorizado.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 w-full">
                                        <div className="mt-2 mb-8 xs:w-10/12 md:w-full mx-auto">
                                            <div className='relative xs:max-w-[calc(100%-4px)] xs:w-[300px] xs:max-h-[235px] xs:h-[350px] md:max-w-[427px] md:max-h-[359px] md:w-full md:h-[458px] mx-auto'>
                                                <Image loading="lazy"
                                                src="https://cdn.fixat.mx/assets/home/img/recibe-declaracion-y-paga-tus-impuestos.png" 
                                                alt="Recibes tu declaración y ¡Pagas tus impuestos!" 
                                                title="Recibes tu declaración y ¡Pagas tus impuestos!"
                                                layout='fill'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
 
export default LearnMore;

